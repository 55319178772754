<template>
  <div class="OnboardingWrap">
    <div class="Onboarding is-flex is-aligned-middle is-aligned-center">
      <div
        class="container Onboarding__content"
      >
        <div class="columns is-centered is-gapless">
          <div
            v-loading="isLoading"
            class="column is-12-mobile is-10-tablet is-8-desktop"
          >
            <base-onboarding-step
              v-if="!isComplete"
              :step="step"
              :total-steps="totalSteps"
              :cover-image="$asset(currentStepImage)"
            >
              <transition mode="out-in" name="fade">
                <component
                  :is="currentStepComponent"
                  :key="`OnboardingStep${step}`"
                  v-model="form"
                  :validator="$v.form"
                  @next="goNext"
                  @prev="goPrev"
                />
              </transition>
            </base-onboarding-step>

            <base-onboarding-step v-else>
              <template slot="body">
                <onboarding-step4
                  :user-email="userEmail"
                />
              </template>
            </base-onboarding-step>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import { isSimpleUrl } from '@hypefactors/shared/js/utils/validation'
import { required } from 'vuelidate/lib/validators'
import { croppieDataFactory } from '@hypefactors/shared/js/factories/croppie'
import { OnboardingApiService } from '@/services/OnboardingApiService'

import BaseOnboardingStep from '@/components/onboarding/BaseOnboardingStep'
import { isLangSupported } from '@hypefactors/shared/js/constants/trans'

const OnboardingStep4 = () => import('@/components/onboarding/register-steps/OnboardingStep4')

export default {
  name: 'Onboarding',

  components: {
    OnboardingStep4,
    BaseOnboardingStep
  },

  props: {
    userId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isLoading: true,
      step: 1,
      totalSteps: 3,
      isUserActivated: false,
      userEmail: '',
      block: false,
      isNewsroomNameUnique: true,

      form: {
        userId: '',
        newsroomName: '',
        website: '',
        countryIso2: '',
        language: '',
        timezone: window.Intl ? window.Intl.DateTimeFormat().resolvedOptions().timeZone : null,
        newsroomLogo: croppieDataFactory(),
        phone: '',

        firstName: '',
        lastName: '',
        address: '',

        contactRole: '',
        contactPhone: '',
        contactEmail: '',

        twitterHandle: '',
        facebookHandle: ''
      }
    }
  },

  async beforeRouteEnter (to, from, next) {
    try {
      await store.dispatch('fetchCountries')
    } catch (err) { }
    next()
  },

  computed: {
    ...mapGetters(['getCountryByIso2']),

    currentStepComponent () {
      const component = import(`@/components/onboarding/register-steps/OnboardingStep${this.step}`)
      return () => component
    },

    /**
     * Returns the image for each step.
     * @returns {string}
     */
    currentStepImage () {
      return this.step === 2 ? '/images/onboarding/step2.jpg' : '/images/onboarding/step1&3.jpg'
    },

    rules () {
      const rules = {}

      if (this.step === 1) {
        rules.newsroomName = { newsroom_name_required: required }
        rules.website = { simple_url: isSimpleUrl }
        rules.countryIso2 = { required }
        rules.language = { required }
        rules.timezone = { required }
      }

      if (this.step === 2) {
        rules.newsroomLogo = {
          original: {}
        }
      }

      if (this.step === 3) {
        rules.firstName = { required }
        rules.lastName = { required }
        rules.contactRole = {}
        rules.phone = {}
      }

      return rules
    },

    isComplete () {
      return this.step > this.totalSteps
    }
  },

  validations () {
    return {
      form: this.rules
    }
  },

  watch: {
    step (step) {
      // send google analytic event to track current onboarding step
      this.$ma.trackEvent({
        category: 'Onboarding',
        action: 'component load',
        label: 'onboarding step ' + step
      }, ['segment', 'facebook'])
    }
  },

  async mounted () {
    try {
      const response = await OnboardingApiService.getUser(this.userId)
      const data = response.data.data

      this.isUserActivated = data.activated
      this.userEmail = data.email
      this.form.userId = data.user_id
      this.form.countryIso2 = data.iso_alpha2

      const country = this.getCountryByIso2(data.iso_alpha2)

      // we do this, so we dont save languages that we do not support in the UI
      if (country && isLangSupported(country.language)) {
        this.form.language = country.language
      }

      if (data.is_onboarding_complete) {
        this.step = this.totalSteps
        this.goNext()
      }
    } catch (error) {
      this.$handleError(error)

      this.$alert(
        this.$t('errors.onboarding_error'),
        {
          title: this.$t('errors.error'),
          type: 'error'
        }
      )

      this.$router.push({ name: 'dashboard.dashboard' })
    }

    this.isLoading = false
  },

  methods: {
    goPrev () {
      this.step--
    },

    goNext () {
      this.step++

      if (this.isComplete && this.isUserActivated) {
        this.$ma.trackEvent({
          category: 'Users',
          action: 'User sign up',
          label: 'hypenews-onboarding'
        }, ['segment', 'facebook'])
        this.$notify.success(this.$t('pages.onboarding.already_activated'))
        this.$redirectTo(this.$getUrl('_redirect/first-newsroom-edit', 'hypenews', false), 1500)
      }
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.OnboardingWrap {
  display: flex;
}

.Onboarding {
  background-color: $grey-lighter;
  min-height: 100vh;
  width: 100%;
}

.Onboarding__content {
  max-width: 1200px;
  width: 100%;
  margin: 0;
}
</style>
